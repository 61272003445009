<template>
  <section id="social-login-settings">
    <b-row class="mb-2">
      <b-col>
        <h1>{{ $t("socialLogin.title") }}</h1>
        <h5 class="text-primary">
          {{ $t("socialLogin.subtitle") }}
        </h5>
      </b-col>
      <b-col class="element-end">
        <b-button
          variant="success"
          @click="$refs.modal.show()"
        >
          <feather-icon
            size="15"
            icon="PlusCircleIcon"
          />
          <span class="ml-25 align-middle">{{ $t("dataGeneric.create") }}</span>
        </b-button>
      </b-col>
    </b-row>
    <b-card>
      <b-table
        :items="socialLogins"
        :fields="tableFields"
        striped
        responsive
      >
        <template #cell(backendName)="data">
          <div class="d-flex align-items-center">
            {{ getBackendText(data.item.backendName) }}
          </div>
        </template>
        <template #cell(key)="data">
          <div class="d-flex align-items-center">
            {{ data.item.key }}
          </div>
        </template>
        <template #cell(secret)="data">
          <div class="secret-cell">
            <b-form>
              <b-form-input
                v-if="showSecret[data.item.id]"
                v-model="data.item.secret"
                autocomplete="new-password"
                type="text"
                readonly
              />
              <b-form-input
                v-else
                autocomplete="new-password"
                type="password"
                value="********"
                readonly
              />
            </b-form>

            <b-button
              variant="link"
              @click="toggleSecretVisibility(data.item.id)"
            >
              <feather-icon :icon="showSecret[data.item.id] ? 'EyeOffIcon' : 'EyeIcon'" />
            </b-button>
            <b-button
              v-clipboard:copy="data.item.secret"
              v-clipboard:success="onCopy"
              v-clipboard:error="onError"
              variant="link"
            >
              <feather-icon icon="FileIcon" />
            </b-button>
          </div>
        </template>
        <template #cell(scope)="data">
          <div class="d-flex align-items-center">
            <span class="scope-text">
              {{ truncateText(data.item.scope, 50) }}
            </span>
            <b-tooltip
              v-if="data.item.scope && data.item.scope.length > 64"
              :target="`scope-tooltip-${data.item.id}`"
              placement="top"
            >
              {{ data.item.scope }}
            </b-tooltip>
            <span :id="`scope-tooltip-${data.item.id}`" />
          </div>
        </template>
        <template #cell(actions)="data">
          <div class="text-nowrap">
            <b-dropdown
              variant="link"
              no-caret
              toggle-class="p-0"
              dropleft
            >
              <template #button-content>
                <feather-icon
                  color="white"
                  icon="MoreVerticalIcon"
                  size="20"
                />
              </template>
              <b-dropdown-item @click.stop.prevent="editSocialLogin(data.item)">
                <feather-icon
                  icon="EditIcon"
                  size="18"
                />
                {{ $t('Edit') }}
              </b-dropdown-item>
              <b-dropdown-item
                @click.stop.prevent="deleteSocialLogin(data.item.id)"
              >
                <feather-icon
                  icon="DeleteIcon"
                  size="18"
                />
                {{ $t('dataGeneric.delete') }}
              </b-dropdown-item>
            </b-dropdown>
          </div>
        </template>
      </b-table>
    </b-card>

    <b-modal
      ref="modal"
      :ok-title="!isEditMode ? $t('socialLogin.create') : $t('dataGeneric.save')"
      :cancel-title="$t('dataGeneric.cancel')"
      size="lg"
      :title="isEditMode ? $t('socialLogin.edit') : $t('socialLogin.create')"
      @ok="validationForm"
      @hidden="resetForm"
    >
      <validation-observer
        ref="socialLogin"
        tag="form"
        @submit.stop.prevent="validationForm"
      >
        <validation-provider
          v-slot="{ errors }"
          name="backend-input"
          rules="required"
        >
          <b-form-group
            :label="$t('socialLogin.backendName')"
            :invalid-feedback="$t('required')"
            :state="errors.length > 0 ? false : null"
          >
            <b-form-select
              id="backendName"
              v-model="newSocialLogin.backendName"
              :state="errors.length > 0 ? false : null"
              :label="$t('message.tableHeader.status')"
            >
              <b-form-select-option
                v-for="s in backendOptions"
                :key="s.value"
                :value="s.value"
              >
                {{ s.text }}
              </b-form-select-option>
            </b-form-select>
          </b-form-group>
        </validation-provider>
        <validation-provider
          v-slot="{ errors }"
          name="key-input"
          rules="required"
        >
          <b-form-group
            :label="$t('socialLogin.key')"
            :invalid-feedback="$t('required')"
            :state="errors.length > 0 ? false : null"
          >
            <b-form-input
              v-model="newSocialLogin.key"
              autocomplete="new-password"
              :state="errors.length > 0 ? false : null"
              maxlength="128"
            />
          </b-form-group>
        </validation-provider>
        <validation-provider
          v-slot="{ errors }"
          name="secret-input"
          rules="required"
        >
          <b-form-group
            :label="$t('socialLogin.secret')"
            :invalid-feedback="$t('required')"
            :state="errors.length > 0 ? false : null"
          >
            <div class="d-flex align-items-center">
              <b-input-group class="mr-1">
                <b-form-input
                  v-model="newSocialLogin.secret"
                  autocomplete="new-password"
                  :state="errors.length > 0 ? false : null"
                  maxlength="128"
                  :type="passwordFieldType"
                />
                <b-input-group-append is-text>
                  <feather-icon
                    :icon="passwordToggleIcon"
                    class="cursor-pointer"
                    @click="togglePasswordVisibility"
                  />
                </b-input-group-append>
              </b-input-group>
              <b-button
                v-clipboard:copy="newSocialLogin.secret"
                v-clipboard:success="onCopy"
                v-clipboard:error="onError"
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                :disabled="newSocialLogin.secret === ''"
                variant="primary"
              >
                {{ $t("dataGeneric.copy") }}
              </b-button>
            </div>
          </b-form-group>
        </validation-provider>
        <b-form-group :label="$t('socialLogin.scope')">
          <b-form-input
            v-model="newSocialLogin.scope"
            autocomplete="new-password"
            maxlength="128"
            :placeholder="$t('socialLogin.scopeSub')"
          />
        </b-form-group>
        <b-form-group
          :label="$t('socialLogin.extraParams')"
          :invalid-feedback="$t('jsonInvalid')"
          :state="isValidJson(newSocialLogin.extraParams)"
        >
          <b-form-textarea
            v-model="newSocialLogin.extraParams"
            :state="isValidJson(newSocialLogin.extraParams)"
            :placeholder="$t('socialLogin.extraParamsSub')"
            rows="3"
          />
        </b-form-group>
      </validation-observer>
    </b-modal>
  </section>
</template>

<script>
import { getUserData } from '@/auth/utils'
import {
  BRow,
  BCol,
  BCard,
  BTable,
  BButton,
  BModal,
  BForm,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BFormSelect,
  BFormSelectOption,
  BInputGroupAppend,
  BInputGroup,
  BDropdown,
  BDropdownItem,
  BTooltip,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import axios from '@axios'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import { required } from '@validations'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import {
  b64ToUtf8, messageError, showToast,
} from '@/store/functions'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BTable,
    BButton,
    BModal,
    BForm,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BFormSelect,
    BFormSelectOption,
    BInputGroupAppend,
    BInputGroup,
    BDropdown,
    BDropdownItem,
    BTooltip,
    ValidationObserver,
    ValidationProvider,
  },
  directives: {
    Ripple,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      required,
      userData: getUserData(),
      socialLogins: [],
      isEditMode: false,
      showSecretInput: false,
      showSecret: {},
      newSocialLogin: {
        backendName: '',
        key: '',
        secret: '',
        scope: '',
        extraParams: '',
      },
      backendOptions: [
        { value: 'SALESFORCE', text: 'SalesForce' },
        { value: 'FIGC', text: 'Figc' },
        { value: 'GOOGLE', text: 'Google' },
        { value: 'FACEBOOK', text: 'Facebook' },
        { value: 'APPLE', text: 'Apple' },
      ],
      tableFields: [
        { key: 'backendName', label: this.$t('socialLogin.backendName') },
        { key: 'key', label: this.$t('socialLogin.key') },
        { key: 'secret', label: this.$t('socialLogin.secret') },
        { key: 'scope', label: this.$t('socialLogin.scope') },
        { key: 'actions', label: this.$t('message.tableHeader.action') },
      ],
      formSubmitAttempted: false,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeOffIcon' : 'EyeIcon'
    },
    isFormValid() {
      if (this.formSubmitAttempted === false) return null
      const backendNameState = this.newSocialLogin.backendName !== null
      const secretState = this.newSocialLogin.secret !== ''
      const keyState = this.newSocialLogin.key !== ''
      const validate = backendNameState && secretState && keyState
      return validate
    },
  },
  created() {
    this.fetchSocialLogins()
  },
  methods: {
    validationForm(bvModalEvt) {
      bvModalEvt.preventDefault()
      return new Promise(resolve => {
        this.$refs.socialLogin.validate().then(success => {
          if (success) {
            resolve(true)
            if (this.isEditMode) { this.updateSocialLogin() } else this.createSocialLogin()
          }
        })
      })
    },
    fetchSocialLogins() {
      axios.post('', {
        query: `{
          allSocialLoginApplications(client: "${this.userData.profile.client.id}") { 
            edges{ 
              node{ 
                id
                backendName
                key
                secret
                scope
                extraParams
              }
            }
          }
        }`,
      }).then(response => {
        this.socialLogins = response.data.data.allSocialLoginApplications.edges.map(edge => edge.node)
      })
    },
    createSocialLogin() {
      const query = `
        mutation($scope: String,$extraParams:JSONString) {
          createSocialLoginApplication(input: {
            client: "${this.userData.profile.client.id}",
            backendName: ${this.newSocialLogin.backendName},
            key: "${this.newSocialLogin.key}",
            secret: "${this.newSocialLogin.secret}",
            scope: $scope,
            extraParams: $extraParams
          }) {
            socialLoginApplication {
              id
              backendName
              key
              secret
              scope
              extraParams
            }
          }
        }`
      const parsedMappings = this.newSocialLogin.extraParams ? JSON.parse(this.newSocialLogin.extraParams) : null
      const variables = {
        scope: this.newSocialLogin.scope !== '' ? this.newSocialLogin.scope : null,
        extraParams: parsedMappings ? JSON.stringify(parsedMappings) : null,
      }

      axios.post('', { query, variables }).then(() => {
        this.fetchSocialLogins()
        this.$refs.modal.hide()
        this.resetForm()
      })
    },
    editSocialLogin(item) {
      this.newSocialLogin = { ...item }
      this.isEditMode = true
      this.$refs.modal.show()
    },
    updateSocialLogin() {
      const id = b64ToUtf8(this.newSocialLogin.id).split(':')[1]
      const query = `
        mutation($scope: String,$extraParams:JSONString) {
          updateSocialLoginApplication(id:${id} ,input: {
            backendName: ${this.newSocialLogin.backendName},
            key: "${this.newSocialLogin.key}",
            secret: "${this.newSocialLogin.secret}",
            scope: $scope,
            extraParams: $extraParams
          }) {
            socialLoginApplication {
              id
              backendName
              key
              secret
              scope
              extraParams
            }
          }
        }`
      const parsedMappings = this.newSocialLogin.extraParams ? JSON.parse(this.newSocialLogin.extraParams) : null
      const variables = {
        scope: this.newSocialLogin.scope !== '' ? this.newSocialLogin.scope : null,
        extraParams: parsedMappings ? JSON.stringify(parsedMappings) : null,
      }

      axios.post('', { query, variables }).then(() => {
        this.fetchSocialLogins()
        this.$refs.modal.hide()
        this.resetForm()
      })
    },
    deleteSocialLogin(id) {
      this.$swal({
        title: this.$t('code.title'),
        text: this.$t('code.text'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$t('code.confirm'),
        cancelButtonText: this.$t('dataGeneric.cancel'),
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          const idToDelete = b64ToUtf8(id).split(':')[1]

          this.show = true
          const config = {
            timeout: 1000 * 60 * 30,
          }

          const data = new FormData()

          const query = `
          mutation{
            deleteSocialLoginApplication(id:"${idToDelete}"){
              found
            }
          }
        `

          data.append('query', query)
          axios
            .post('', data, config)
            .then(res => {
              messageError(res, this)
              showToast(this.$t('success'), 1, this)

              this.fetchSocialLogins()
            })
            .catch(() => {
              showToast(this.$t('error'), 2, this)
            })
        }
      })
    },
    resetForm() {
      this.newSocialLogin = {
        backendName: '',
        key: '',
        secret: '',
        scope: '',
        extraParams: '',
      }
      this.isEditMode = false
      this.formSubmitAttempted = false
    },
    toggleSecretVisibility(id) {
      this.$set(this.showSecret, id, !this.showSecret[id])
    },
    getBackendText(value) {
      const selectedOption = this.backendOptions.find(option => option.value === value)
      return selectedOption ? selectedOption.text : 'Unknown'
    },
    onCopy() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: this.$t('code.tCopy'),
          icon: 'BellIcon',
        },
      })
    },
    onError() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: this.$t('code.errorCopy'),
          icon: 'BellIcon',
        },
      })
    },
    truncateText(text, limit) {
      if (text && text.length > limit) {
        return `${text.slice(0, limit)}...`
      }
      return text
    },
    isValidJson(jsonString) {
      if (!jsonString) return null
      try {
        const parsed = JSON.parse(jsonString)
        return typeof parsed === 'object' && parsed !== null
      } catch (error) {
        return false
      }
    },
  },
}
</script>

<style lang="scss" scoped>
#social-login-settings .element-end{
  text-align: end;
  align-self: center;

}
#social-login-settings .col-form-label {
  font-size: inherit;
  line-height: 1.45;
}
#social-login-settings .secret-cell {
  display: flex;
  align-items: center;
  gap: 4px; /* Adjust the gap as needed */
}

#social-login-settings .secret-cell .form-control {
  margin-right: 4px; /* Adjust the margin as needed */
}

#social-login-settings .secret-cell .btn {
  padding: 0;
  margin: 0;
}

#social-login-settings .scope-text {
  white-space: nowrap; /* Prevent text from wrapping */
  overflow: hidden; /* Hide overflow */
  text-overflow: ellipsis; /* Show ellipsis for overflow */
  max-width: 200px; /* Adjust the max-width as needed */
}
</style>
